import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div>
      <h2 className="font-bold text-3xl"></h2>
      <h3>Page Not Found</h3>
      <Link to="/">Go back to Home</Link>
    </div>
  );
};

export default NotFound;
